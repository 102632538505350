import Head from 'next/head';
import Image from 'next/image';
import styled from 'styled-components';
import { Button } from '@mantine/core';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import FlexRowWrapper from 'ui/wrappers/FlexRowWrapper';

const AdminWrapper = styled.main`
  position: relative;
  width: 100%;
  min-height: calc(100vh);

  background: url('/backgrounds/gradient-bg.png') no-repeat center fixed;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 12px;

  .login-section {
    width: 396px;
    height: 295px;

    background: #ffffff;
    border-radius: 8px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > ${FlexRowWrapper} {
      flex: 0 1 80%;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .alpha-pill {
        width: 52px;
        height: 20px;
        background: rgba(50, 50, 50, 0.13);
        border-radius: 3px;

        display: grid;
        place-items: center;

        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        color: rgba(0, 0, 0, 0.46);
      }
    }

    button {
      flex-shrink: 0;

      background: #326efc;
      border-radius: 5px;
    }
  }

  .secondary-section {
    width: 396px;
    height: auto;

    background: #ffffff;
    border-radius: 8px;
    padding: 8px;

    gap: 12px;

    img {
      border-radius: 4px;
      background-color: black;
    }

    .info {
      display: flex;
      flex-flow: column nowrap;
      gap: 4px;

      .heading {
        font-size: 16px;
        font-weight: bold;
      }

      a {
        font-size: 14px;
        text-decoration: none;
        color: grey;
      }
    }
  }
`;

export default function Admin() {
  return (
    <AdminWrapper>
      <Head>
        <title>Airdropr | Admin</title>
      </Head>
      <div className="login-section">
        <FlexRowWrapper>
          <Image
            src="/logos/airdropr-coloured.svg"
            alt=""
            width={180}
            height={41}
            priority
          />
          <div className="alpha-pill">Beta</div>
        </FlexRowWrapper>
        <ConnectButton.Custom>
          {({ account, chain, openConnectModal, mounted }) => {
            return (
              <div
                style={{ width: '100%' }}
                {...(!mounted && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                    width: '100%',
                  },
                })}
              >
                {(() => {
                  if (!mounted || !account || !chain) {
                    return (
                      <Button
                        fullWidth
                        onClick={openConnectModal}
                        type="button"
                      >
                        Connect Wallet
                      </Button>
                    );
                  }
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
      <FlexRowWrapper className="secondary-section">
        <Image
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          alt=""
          width={48}
          height={48}
          priority
          unoptimized
        />
        <div className="info">
          <p className="heading">You&apos;ll need our beta NFT to get in!</p>
          <a href="https://claim.airdropr.com/mint">
            Free to claim, get it here &rarr;
          </a>
        </div>
      </FlexRowWrapper>
    </AdminWrapper>
  );
}
